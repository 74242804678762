<template>
	<ViewHomeCard title="公告通知">
    <div class="overY">
      <template v-if="getList.length">
        <div v-for="item of getList" :key="item.id" class="postMsgRow px2 py2 mb2 fontSm bottomLine">
          <span class="postRowItem textLeft">
            <span v-if="item.namezh" class="noticeHeader plSm">{{ item.namezh }}</span>
            <span class="fontW4 noticeMention plSm" v-html="item.memo"></span>
          </span>
          <span class="postRowItem fontW4 textRight">
            <span>{{ item.diffDay }}</span>
            <span>{{ item.pubTime }}</span>
          </span>
        </div>
      </template>
    </div>
  </ViewHomeCard>
</template>

<script setup lang="ts">
import ViewHomeCard from '@/components/home/ViewHomeCard.vue';
import { computed } from 'vue';

const props = defineProps<{
  dataList: any[]
}>();

const getList = computed(() => {
  return props.dataList.map((v: Record<string, any>) => {
    if (v.type === 'prodctInfo') {
      v.style = { color: 'red' };
    } else if (v.type === 'priceInfo') {
      v.style = { color: 'var(--proj-main-color)' };
    }
    return v;
  });
});
</script>

<style lang="scss" scoped>
.postMsgRow {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 80px;

  &:hover {

    &::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background-color: var(--proj-main-color);
      opacity: .1;
    }
  }

  .postRowItem {
      display: grid;
      grid-template-rows: 24px 1fr;
      row-gap: 8px;

      .noticeHeader {
        font-weight: 500;
        border-bottom: 1px solid rgba($color: #aaa, $alpha: .4);
      }

      .noticeMention {
        white-space: wrap;
        word-wrap: break-word;
        word-break: break-all;
      }
  }
}
</style>
