<template>
	<ViewHomeCard title="本月生日提醒" :count-str="!dataList.length ? '' : `计：${getList.length} 人`">
    <template #ctrlCell>
      <a-switch v-model="showAll" class="ml2" style="min-width: 106px;" type="round" :checked-color="styleMgr.getPrimaryColor">
        <template #checked>包含本月所有</template>
        <template #unchecked>仅包含未到期</template>
      </a-switch>
    </template>
    <THomeList v-if="dataList.length" :headers="headers" :data-list="getList"></THomeList>
  </ViewHomeCard>
</template>

<script setup lang="ts">

import type { IHomeListHeader } from '@/types/ui';
import { ref, computed } from 'vue';
import ViewHomeCard from '@/components/home/ViewHomeCard.vue';
import THomeList from '@/components/home/THomeList.vue';
import { useStyle } from '@/stores/sys/useStyle';

const styleMgr = useStyle();
const headers: IHomeListHeader[] = [
  { title: '姓名', dataIndex: 'namezh', align: 'center', width: 240 },
  { title: '今年公历生日', dataIndex: 'birthDayToBe', align: 'center', width: 240 },
  { title: '年龄', dataIndex: 'age', align: 'center', width: 240 },
  { title: '距离', dataIndex: 'diffDay', align: 'center', width: 240 },
  { title: '性别', dataIndex: 'genderNamezh', align: 'center', width: 240 },
  { title: '部门', dataIndex: 'deptIdNamezh', align: 'center' }
];

const props = defineProps<{
  dataList: any[]
}>();

const showAll = ref<boolean>(false);

const getList = computed(() => {
  return (showAll.value ? props.dataList : props.dataList.filter((v: Record<string, any>) => v.diffDayVal > -1)).map((v: Record<string, any>) => {
    if (!v.diffDayVal) {
      v.style = { color: 'orangered' };
    } else if (v.diffDayVal < 0) {
      v.style = { color: '#aaa' };
    }
    return v;
  }).sort((a: Record<string, any>, b: Record<string, any>) => {
    return (!a.diffDayVal && -99999999999999)
    || (!b.diffDayVal && -99999999999999)
    || (a.diffDayVal - b.diffDayVal);
  });
});
</script>
