import '@/assets/css/base.css';
import '@/assets/scss/base.scss';

import { createApp } from 'vue';
import { createPinia } from 'pinia';

import App from './App.vue';
import { router } from './router';

createApp(App)
.use(createPinia())
.use(router)
.mount('#app');
