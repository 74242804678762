import type { IAppProfile, IUseApp } from '@/types/store/useApp';
import { defineStore, createPinia } from 'pinia';
import { appName } from '../../constant/app';
import { version } from '../../../../../package.json';
import { computed } from 'vue';


export const storeApp = defineStore('useApp', (): IUseApp => {
  const getApp = computed((): IAppProfile => ({
    appName,
    appNameHtml: appName.replace(/\s+/g, '&nbsp;'),
    appVer: version
  }));

  return {
    getApp
  };
});

// // @ts-ignore
// if (import.meta.hot) {
//   // @ts-ignore
//   import.meta.hot.accept(acceptHMRUpdate(useApp, import.meta.hot));
// }

export const useApp = () => storeApp(createPinia());
