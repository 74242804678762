<template>
  <div class="tPageWrapper fullW-W fullH-H overHidden">
      <template v-if="startPage">
      <slot>
      </slot>
      <TCopyright :copyright-top-line="copyrightTopLine"></TCopyright>
    </template>
  </div>
</template>

<script setup lang="ts">
import type { IPageFeatur } from '@/types/ui';
import { ref, onBeforeMount } from 'vue';
import TCopyright from '@/components/widget/TCopyright.vue';
import { setLoadding } from '@/util/uiKit/setLodding';

defineProps<IPageFeatur & { copyrightTopLine?: boolean }>();

const startPage = ref(false);

onBeforeMount(() => {
  setLoadding(false);
  startPage.value = true;
});
</script>

<style lang="scss" scoped>
.tPageWrapper {
  position: relative;
  display: grid;
  grid-template-rows: 1fr 32px;
}
</style>
