import type { IUseNetwork } from '@/types/store/useNetwork';
import { defineStore } from 'pinia';
import { ref, onMounted, onUnmounted } from 'vue';
import { Toaster } from '@/util/uiKit/toaster';

export const useNetwork = defineStore('useNetwork', (): IUseNetwork => {
  const onLine = ref(true);


  const handleNetworkChange = () => {
    onLine.value = navigator.onLine;
    if (!onLine.value) {
      Toaster.error('网络已断开');
    } else {
      Toaster.success('网络已恢复');
    }
  };

  onMounted(() => {
    window.addEventListener('online', handleNetworkChange);
    window.addEventListener('offline', handleNetworkChange);
  });

  onUnmounted(() => {
    window.removeEventListener('online', handleNetworkChange);
    window.removeEventListener('offline', handleNetworkChange);
  });

  return {
    onLine
  };
});
