<template>
    <template v-for="item of dataMenu" :key="item.code">
      <template v-if="!item.subs?.length">
        <a-menu-item :key="item.code" @click="onItemClick(item)"><template #icon><IconMenu /></template>{{ item.namezh }}</a-menu-item>
      </template>
      <a-sub-menu v-else :key="`${item.code}-sub`">
        <template #icon><IconMenu /></template>
        <template #title>{{ item.namezh }}</template>
        <TMenuItem :data-menu="item.subs" @on-nav="onItemClick"></TMenuItem>
      </a-sub-menu>
    </template>
</template>

<script setup lang="ts">
import type { IMenuItem } from 'shangshi_types';
import { IconMenu } from '@arco-design/web-vue/es/icon';

withDefaults(defineProps<{ dataMenu: IMenuItem[] }>(), {
  dataMenu: () => ([])
});

const emit = defineEmits<{
  (e: 'onNav', val: IMenuItem): void
}>();

const onItemClick = (val: IMenuItem) => {
  emit('onNav', val);
};
</script>
