<template>
  <a-menu accordion class="fontW4" style="font-size: 12px;" :style="getSize" :default-selected-keys="[dataMenu?.[0].code]"
  show-collapse-button breakpoint="xl" @collapse="changeCollapse">
  <TMenuItem :data-menu="dataMenu" @on-nav="emit('onNav', $event)"></TMenuItem>
  </a-menu>
</template>

<script setup lang="ts">
import type { IMenuItem } from 'shangshi_types';
import { computed } from 'vue';
import { strNumToCss } from '@/util/bizKit/propToVal';
import TMenuItem from './TMenuItem.vue';

interface IPropMenu {
  /** 菜单宽度 */
  width?: string | number;
  /** 菜单高度 */
  height?: string | number;
  /** 菜单数据容器 */
  dataMenu: IMenuItem[]
}

const props = withDefaults(defineProps<IPropMenu>(), {
  width: '256px',
  height: '80vh',
  dataMenu: () => ([])
});

const emit = defineEmits(['onCollapse', 'onNav']);

const getSize = computed(() => {
  return {
    width: strNumToCss(props.width, '256px'),
    height: strNumToCss(props.height, '50vh')
  };
});

const changeCollapse = (e: boolean) => {
  emit('onCollapse', e);
};
</script>
