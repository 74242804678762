<template>
  <div>
    <div ref="bodyAnimation" :style="asyncStyle"></div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, onMounted } from 'vue';
import lottie from 'lottie-web';

const props = withDefaults(defineProps<{
  options: {
    loop?: boolean,
    autoplay?: boolean,
    assetsPath: string
  },
  width?: number,
  height?: number
}>(), {
  width: 200,
  height: 200
});

const emit = defineEmits(['animCreated']);

const bodyAnimation = ref<Element | null>(null);
const asyncStyle = computed(() => ({
  width: props.width ? `${props.width}px` : '100%',
  height: props.height ? `${props.height}px` : '100%',
  overflow: 'hidden',
  margin: '0 auto'
}));

onMounted(() => {
  if (bodyAnimation.value) {
    const anim = lottie.loadAnimation({
        // @ts-ignore
        container: bodyAnimation.value,
        renderer: 'svg',
        loop: props.options.loop !== false,
        autoplay: props.options.autoplay !== false,
        path: props.options.assetsPath
        // rendererSettings: props.options?.rendererSettings || {}
      });
      emit('animCreated', anim);
  }
});
</script>
