/** 组件的属性定义转值 */

/** 将 string | number 类型的宽高属性转换为 css 尺寸值
 *
 * @param val 要转换的属性值
 * @param defaultVal 在属性缺失或类型无效时的默认值
 * @returns
 */
export const strNumToCss = (val: number | string, defaultVal = '100%'): string => {
  const tp = typeof val;
  return (tp === 'string' && val as string) || (tp === 'number' && `${val}px`) || defaultVal;
};
