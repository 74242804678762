<template>
  <ViewHomePanel>
    <ViewHomeCard title="统计"></ViewHomeCard>
    <ViewHomeRemindList :data-list="remindList"></ViewHomeRemindList>
    <ViewHomeBirthDayList :data-list="birthdayList"></ViewHomeBirthDayList>
    <template #right>
      <ViewHomePostList :data-list="postList"></ViewHomePostList>
    </template>
  </ViewHomePanel>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue';
import ViewHomePanel from '@/components/home/ViewHomePanel.vue';
import ViewHomeCard from '@/components/home/ViewHomeCard.vue';
import ViewHomeBirthDayList from '@/components/home/ViewHomeBirthDayList.vue';
import ViewHomePostList from '@/components/home/ViewHomePostList.vue';
import ViewHomeRemindList from '@/components/home/ViewHomeRemindList.vue';
import { apiGetRemind } from '@/util/useApi';
import { Toaster } from '@/util/uiKit/toaster';

const postList = ref<Record<string, any>[]>([]);
const remindList = ref<Record<string, any>[]>([]);
const birthdayList = ref<Record<string, any>[]>([]);

onMounted(async () => {
	const { post = [], remind = [], birthDay = [] } = await apiGetRemind() || {};
  postList.value = post;
  remindList.value = remind;
  birthdayList.value = birthDay;
  await Toaster.birthDayTip(birthDay.filter((v: Record<string, any>) => !v.diffDayVal).map((v: Record<string, any>) => v.namezh));
});
</script>
