<template>
  <div class="hoemListWrapper fullW overHidden">
    <div class="rowItem bottomLine rowHeaders fontSm fontW7" :style="getGridStyle">
      <span class="textCenter">序号</span>
      <span :class="{ textCenter: item.align === 'center' }" v-for="item of headers" :key="item.title">{{ item.title }}</span>
    </div>
    <div class="overY overHiddenX rowHeaders">
      <div class="rowItem bodyRow py1 bottomLine fontSm fontW4 hoverRow" :style="{ ...getGridStyle, ...(item.style || {}) }"
        v-for="(item, index) of dataList" :key="item.id">
        <span class="textCenter">{{ index + 1 }}</span>
        <template v-for="itemH of headers" :key="itemH.title">
          <span v-if="htmlLike" :class="{ textCenter: itemH.align === 'center' }" v-html="item[itemH.dataIndex]"></span>
          <span v-else :class="{ textCenter: itemH.align === 'center' }">{{ item[itemH.dataIndex] }}</span>
        </template>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { IHomeListHeader } from '@/types/ui';
import { computed } from 'vue';

const props = withDefaults(defineProps<{
  headers: IHomeListHeader[],
  dataList: Record<string, any>[],
  /** 若为false，则以字符展示，为真，则以html形式展示 */
  htmlLike?: boolean
}>(), {
  dataList: () => []
});

const getGridStyle = computed(() => {
  return {
    // @ts-ignore
    'grid-template-columns': `30px ${props.headers.map((v: IHomeListHeader) => {
      // const val = v.width;
      // return (!val && '1fr') || (((typeof val === 'string') && /(px|%)$/.test(val)) && val) || (+(val || 0) && `${val}px`) || 'ifr';
      return '1fr';
    }).join(' ')}`
  };
});
</script>

<style lang="scss" scoped>
.hoemListWrapper {
  position: relative;
  display: grid;
  grid-template-rows: 24px 1fr;
  row-gap: 0;

  .rowItem {
    display: grid;
    min-height: 16px;

    &.rowHeaders {
      color: #333;
    }

    &.bodyRow {
      position: relative;

      &:hover {

        &::after {
          content: '';
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          background-color: var(--proj-main-color);
          opacity: .1;
        }
      }
    }
  }
}
</style>
