import type { IStyle } from '@/types/store/useStyle';
import { defineStore } from 'pinia';
import { computed } from 'vue';

export const useStyle = defineStore('useStyle', (): IStyle => {
  const getPrimaryColor = computed(() => {
    const root = document.documentElement;
    return getComputedStyle(root).getPropertyValue('--proj-main-color').trim();
  });


  return {
    getPrimaryColor
  };
});
