const LOADDING_ID = 'inLoading';

const loadingHtml = (title: string) => `<div class="flexVXY loaddingMask">
    <svg width="48" height="48" viewBox="0 0 135 140" xmlns="http://www.w3.org/2000/svg" fill="hsl(0, 100%, 50%)">
        <rect y="10" width="15" height="120" rx="6">
            <animate attributeName="height" begin="0.5s" dur="1s" values="120;110;100;90;80;70;60;50;40;140;120" calcMode="linear" repeatCount="indefinite" />
            <animate attributeName="y" begin="0.5s" dur="1s" values="10;15;20;25;30;35;40;45;50;0;10" calcMode="linear" repeatCount="indefinite" />
        </rect>
        <rect x="30" y="10" width="15" height="120" rx="6">
            <animate attributeName="height" begin="0.25s" dur="1s" values="120;110;100;90;80;70;60;50;40;140;120" calcMode="linear" repeatCount="indefinite" />
            <animate attributeName="y" begin="0.25s" dur="1s" values="10;15;20;25;30;35;40;45;50;0;10" calcMode="linear" repeatCount="indefinite" />
        </rect>
        <rect x="60" width="15" height="140" rx="6">
            <animate attributeName="height" begin="0s" dur="1s" values="120;110;100;90;80;70;60;50;40;140;120" calcMode="linear" repeatCount="indefinite" />
            <animate attributeName="y" begin="0s" dur="1s" values="10;15;20;25;30;35;40;45;50;0;10" calcMode="linear" repeatCount="indefinite" />
        </rect>
        <rect x="90" y="10" width="15" height="120" rx="6">
            <animate attributeName="height" begin="0.25s" dur="1s" values="120;110;100;90;80;70;60;50;40;140;120" calcMode="linear" repeatCount="indefinite" />
            <animate attributeName="y" begin="0.25s" dur="1s" values="10;15;20;25;30;35;40;45;50;0;10" calcMode="linear" repeatCount="indefinite" />
        </rect>
        <rect x="120" y="10" width="15" height="120" rx="6">
            <animate attributeName="height" begin="0.5s" dur="1s" values="120;110;100;90;80;70;60;50;40;140;120" calcMode="linear" repeatCount="indefinite" />
            <animate attributeName="y" begin="0.5s" dur="1s" values="10;15;20;25;30;35;40;45;50;0;10" calcMode="linear" repeatCount="indefinite" />
        </rect>
    </svg>
    <p class="textWhite mt1 fontSm fontW1 noSelect textRed">${ title }...</p>
</div>`.replace(/\n\s+</g, '<');

const addLoadding = (title: string) => {
  const elLoadding = document.getElementById(LOADDING_ID);
  if (!elLoadding) {
    const elm = document.createElement('div');
    elm.id = LOADDING_ID;
    elm.className = 'maskerLoading flexXY loading-enter';
    elm.innerHTML = loadingHtml(title);
    document.body.appendChild(elm);
  }
};

const removeLoadding = () => {
  const elLoadding = document.getElementById(LOADDING_ID);
  if (elLoadding) {
    elLoadding.remove();
  }
};

/** 设置加载中
 *
 * @param status 指示应该显示还是隐藏
 * @param title '加载中...'字样的自定义文本
 */
export const setLoadding = (status: boolean, title?: string) => {
  if (status) {
    addLoadding(title || '加载中');
  } else {
    removeLoadding();
  }
};
