<template>
  <div class="tNavBar px2 bottomLine shadow">
    <div class="flexY">
      <a-breadcrumb class="breadCrumbItem" style="font-size: 12px;">
        <a-breadcrumb-item v-for="item of breadCrumbs" :key="item">{{ item }}</a-breadcrumb-item>
      </a-breadcrumb>
    </div>
    <div class="flexXY">
      <slot></slot>
    </div>
    <div class="flexY pr4">
      <span class="flexSplit"></span>
      <!-- <span class="px2 fontSm noSelect">{{ appMgr.appName }}&nbsp;/&nbsp;v{{ appMgr.appVer }}</span> -->
      <IconDocs :size="16" class="handLike" @click="jumpTo('https://www.shangshi-szh.com/docs')" />
      <IconSvr :size="18" class="handLike mx2" @click="jumpTo('https://www.shangshi-szh.com:9090')" />
      <IconSun v-show="inDark" :size="16" class="handLike" @click="setDark()" />
      <IconMoon v-show="!inDark" :size="16" class="handLike" @click="setDark()" />
      <a-avatar class="mx2" :size="16">{{ (userInfo.code?.split('')?.[0] || 'U').toUpperCase() }}</a-avatar>
      <a-dropdown :popup-max-height="false">
        <span class="handLike fontSm noSelect">{{ userInfo.namezh || '未登录' }}<icon-down /></span>
        <template #content>
          <a-doption v-if="userInfo.namezh" @click="emit('onPwdChange')"><span class="fontSm">更改密码</span></a-doption>
          <a-doption @click="emit('onSignOut')"><span class="fontSm">退出登录</span></a-doption>
        </template>
      </a-dropdown>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { IUserCache } from '@/types/store/useUser';
import { IconDown, IconSun, IconMoon } from '@arco-design/web-vue/es/icon';
import IconSvr from '@/components/icons/IconSvr.vue';
import IconDocs from '@/components/icons/IconDocs.vue';
import { ref } from 'vue';

defineProps<{
  breadCrumbs: string[],
  userInfo: IUserCache,
  currTitle: string
}>();

const emit = defineEmits(['onSignOut', 'onPwdChange']);

// const appMgr = useApp().getApp;
const inDark = ref(false);

const setDark = () => {
  inDark.value = !inDark.value;
  document.body.setAttribute('arco-theme', inDark.value ? 'dark' : '');
};

const jumpTo = (url: string) => {
  window.open(url);
};
</script>

<style lang="scss" scoped>
.tNavBar {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  background-color: hsla(0, 0%, 100%, 0.1);
  backdrop-filter: blur(10px);

  .breadCrumbItem {
    border-left: 3px var(--color-primary) solid;
  }
}
</style>
