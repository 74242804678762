<template>
<div class="copyrightWrapper flexXY fullW-W overHidden" :class="copyrightTopLine ? 'topLine' : ''" :data-str2="copyright"></div>
</template>

<script setup lang="ts">
import { smpoo } from 'tmind-core';
defineProps<{ copyrightTopLine?: boolean }>();
const copyright = `${smpoo().appCopy} • All rights reserved. Power by tFrameV9.5`;
</script>

<style lang="scss" scoped>
.copyrightWrapper {
  position: relative;
  width: 100dvw;
  height: 32px;

  &::after {
    position: absolute;
    // content: 'Copyright © 2023 by 上海深普软件有限公司 All rights reserved. Power by tFrameV9.5';
    content: attr(data-str2);
    width: 100%;
    height: 32px;
    line-height: 32px;
    text-align: center;
    top: 0;
    left: 0;
    font-size: 12px;
    font-weight: 100;
    text-align: center;
    color: #999;
    overflow: hidden;
  }
}
</style>
