<template>
  <ViewSign v-if="!signed" @on-signed="onSigned"></ViewSign>
  <ViewBizHome :network-err="!netMgr.onLine" v-else @on-sign-out="onSignOut"></ViewBizHome>
</template>

<script setup lang="ts">
import ViewSign from '@/components/ViewSign.vue';
import ViewBizHome from '@/components/ViewBizHome.vue';
import { ref, onBeforeMount } from 'vue';
import { useNetwork } from '@/stores/sys/useNetwork';
import { useUser } from '@/stores/sys/useUser';

const netMgr = useNetwork(); // 网络通断监听

const signed = ref<boolean>(false);
const userMgr = useUser();

const onSigned = (e: boolean) => {
  signed.value = e;
};

const onSignOut = () => {
  signed.value = false;
  userMgr.signOut();
};

onBeforeMount(() => {
  signed.value = userMgr.signStatus;
});
</script>
