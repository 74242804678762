<template>
	<ViewHomeCard title="事项提醒" :count-str="!dataList.length ? '' : ` ${dataList.length} 项`">
    <THomeList v-if="dataList.length" :headers="headers" :data-list="getList" html-like></THomeList>
  </ViewHomeCard>
</template>

<script setup lang="ts">
import type { IHomeListHeader } from '@/types/ui';
import { computed } from 'vue';
import ViewHomeCard from '@/components/home/ViewHomeCard.vue';
import THomeList from '@/components/home/THomeList.vue';

const headers: IHomeListHeader[] = [
  { title: '事项', dataIndex: 'memo', align: 'center' },
  { title: '事项负责人', dataIndex: 'chargeManIdNamezh', align: 'center', width: 120 },
  { title: '(督/协)办人', dataIndex: 'superviseIdNamezh', align: 'center', width: 120 },
  { title: '到期日期', dataIndex: 'dateOfEffect', align: 'center', width: 120 },
  { title: '剩余天数', dataIndex: 'diffDay', align: 'center', width: 120 },
  { title: '提醒类型', dataIndex: 'typeNamezh', align: 'center', width: 120 }
];

const props = defineProps<{
  dataList: any[]
}>();

const getList = computed(() => {
  return props.dataList.map((v: Record<string, any>) => {
    if (!v.diffDayVal) {
      v.style = { color: 'red' };
    } else if (v.diffDayVal < 0) {
      v.style = { color: '#aaa' };
    }
    return v;
  });
});
</script>
