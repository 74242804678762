import { type RouteRecordRaw, createRouter, createWebHashHistory } from 'vue-router';
import { subMetas } from './subs';
import { getSubRouters } from '@/util/routerUtil';
import ViewHome from '@/pages/ViewHome.vue';

const routes: RouteRecordRaw[] = [
  {
    path: '/', name: 'ViewHome', meta: { pathTitle: ['首页'] }, component: ViewHome
  },
  ...getSubRouters(subMetas)
];

export const router = createRouter({
  // history: createWebHistory('/'),
  history: createWebHashHistory('/'),
  routes,
  scrollBehavior: () => ({ top: 0 })
});
