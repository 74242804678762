import type { TCacheStoreName } from '@/types/store/useApp';
import localforage from 'localforage';
const name = 'shangshi';
const driver = localforage.INDEXEDDB;

const CACHE_STORE: Record<TCacheStoreName, typeof localforage | null> = {
  common: null,
  sysDb: null,
  kvSource: null,
  payload: null,
  colDefs: null,
  pullPulDefine: null,
  customerCol: null,
  counterRoot: null
};

/** 获取一份约定范围的缓存表
 *
 * @param storeName 缓存表名称
 * @returns
 */
const getCacheTable = (storeName: TCacheStoreName): typeof localforage => {
  if (!CACHE_STORE[storeName]) {
    CACHE_STORE[storeName] = localforage.createInstance({
      name,
      storeName,
      driver
    });
  }
  return CACHE_STORE[storeName]!;
};

// #region 固定表名形式读写
/** 从缓存读取
 *
 * @param storeName 要读取的库名称
 * @param key 要读取的缓存表名称
 * @returns
 */
export const getCache = async (storeName: TCacheStoreName, key: string): Promise<any> => {
  return await getCacheTable(storeName).getItem(key);
};

/** 执行缓存写入
 *
 * @param storeName 要读取的库名称
 * @param key 要读取的缓存表名称
 * @param val 值
 */
export const setCache = async (storeName: TCacheStoreName, key: string, val: any) => {
  await getCacheTable(storeName).setItem(key, val);
};

/** 从指定库中移除指定的键值
 *
 * @param storeName 键值所在的库
 * @param key 要移除的目标键名
 */
export const delCache = async (storeName: TCacheStoreName, key: string): Promise<void> => {
  await getCacheTable(storeName).removeItem(key);
};

/** 清空指定的库
 *
 * @param storeName 要执行清空操作的库名称
 */
export const clearTable = async (storeName: TCacheStoreName): Promise<void> => {
  await getCacheTable(storeName).clear();
};

/** 移除缓存中的所有库 */
export const dropCache = async (): Promise<void> => {
  const tbls: TCacheStoreName[] = Object.keys(CACHE_STORE) as TCacheStoreName[];
  for (const v of tbls) {
    await clearTable(v);
    CACHE_STORE[v as TCacheStoreName] = null;
  }
};
// #endregion
