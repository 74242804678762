const encode = (str) => str.split("").map((v) => v.charCodeAt(0));
const decode = (val, sep) => String.fromCharCode(...Array.isArray(val) ? val : val.split(sep || "-").map((v) => +v));
const encodeBase64 = (str) => {
  if (!["string", "bigint", "number", "boolean"].includes(typeof str)) {
    throw new Error("\u65E0\u6548\u7684\u53C2\u6570\u7C7B\u578B\uFF0C\u53EA\u5141\u8BB8\u5C06\u5B57\u7B26\u4E32\uFF0C\u6570\u5B57\uFF0C\u5E03\u5C14\u578B\u7B49\u53C2\u6570\u8F6C\u6362\u4E3A base64 \u503C");
  } else {
    try {
      return window.btoa(unescape(encodeURIComponent(str)));
    } catch (err) {
      throw new Error(`\u8F6C\u6362\u5931\u8D25\uFF1A${err.message}`);
    }
  }
};
const decodeBase64 = (b64Str) => {
  try {
    return decodeURIComponent(escape(atob(b64Str)));
  } catch (err) {
    console.error("\u8F6C\u6362\u5931\u8D25\uFF01", err);
    throw err;
  }
};

const ECHO_COLOR = {
  bold: ["\x1B[1m", "\x1B[22m"],
  blue: ["\x1B[34m", "\x1B[39m"],
  green: ["\x1B[32m", "\x1B[39m"],
  yellow: ["\x1B[33m", "\x1B[39m"],
  red: ["\x1B[31m", "\x1B[39m"],
  blueBG: ["\x1B[44m", "\x1B[49m"],
  greenBG: ["\x1B[42;30m", "\x1B[49m"],
  yellowBG: ["\x1B[43;30m", "\x1B[49m"],
  redBG: ["\x1B[41m", "\x1B[49m"],
  end: ["\x1B[0m"]
};
const MSG_TYPE = {
  INFO: {
    name: "INFO",
    func: console.info,
    color: ["\u4FE1\u606F", ECHO_COLOR.blueBG[0], ECHO_COLOR.blueBG[1], ECHO_COLOR.blue[0], ECHO_COLOR.blue[1]]
  },
  SUCC: {
    name: "SUCC",
    func: console.log,
    color: ["\u6210\u529F", ECHO_COLOR.greenBG[0], ECHO_COLOR.greenBG[1], ECHO_COLOR.green[0], ECHO_COLOR.green[1]]
  },
  WARN: {
    name: "WARN",
    func: console.warn,
    color: ["\u8B66\u544A", ECHO_COLOR.yellowBG[0], ECHO_COLOR.yellowBG[1], ECHO_COLOR.yellow[0], ECHO_COLOR.yellow[1]]
  },
  ERR: {
    name: "ERR",
    func: console.error,
    color: ["\u9519\u8BEF", ECHO_COLOR.redBG[0], ECHO_COLOR.redBG[1], ECHO_COLOR.red[0], ECHO_COLOR.red[1]]
  }
};
const _echo_ = (msg, type, title) => {
  const ctrlObj = MSG_TYPE[type];
  const _func = ctrlObj.func;
  const [a, b, c, d, e] = ctrlObj.color;
  _func(`${b} ${title || a} ${c} ${d} ${msg} ${e}`);
};
const tLog = (...msg) => {
  console.log(...msg);
};
const tEcho = (msg, type, title) => {
  _echo_(msg, type || "INFO", title || "");
};
const tClear = () => {
  console.clear();
};
const tLine = (str) => {
  const _str = str ? ` ${str} ` : "";
  console.log(`
--------------------------------------${_str}--------------------------------------
`);
};
const tRow = (repeat = 1) => {
  console.log(new Array(repeat).fill("\n").join(""));
};

const _strBrand = `32-32-32-32-32-32-95-95-95-32-32-32-32-32-32-32-32-32-32-32-95-95-95-32-32-32-32-32-32-32-32-32-32-32-95-95-95-32-32-32-32-
32-32-32-32-32-32-32-95-95-95-32-32-32-32-32-32-32-32-32-32-32-95-95-95-32-32-32-32-32-10-32-32-32-32-32-47-92-32-32-92-32-32-32-32-32-32-32-
32-32-47-92-95-95-92-32-32-32-32-32-32-32-32-32-47-92-32-32-92-32-32-32-32-32-32-32-32-32-47-92-32-32-92-32-32-32-32-32-32-32-32-32-47-92-32-32-
92-32-32-32-32-10-32-32-32-32-47-58-58-92-32-32-92-32-32-32-32-32-32-32-47-58-58-124-32-32-124-32-32-32-32-32-32-32-47-58-58-92-32-32-92-32-32-
32-32-32-32-32-47-58-58-92-32-32-92-32-32-32-32-32-32-32-47-58-58-92-32-32-92-32-32-32-10-32-32-32-47-58-47-92-32-92-32-32-92-32-32-32-32-32-
47-58-124-58-124-32-32-124-32-32-32-32-32-32-47-58-47-92-58-92-32-32-92-32-32-32-32-32-47-58-47-92-58-92-32-32-92-32-32-32-32-32-47-58-47-92-
58-92-32-32-92-32-32-10-32-32-95-92-58-92-45-92-32-92-32-32-92-32-32-32-47-58-47-124-58-124-95-95-124-95-95-32-32-32-47-58-58-92-45-92-58-92-
32-32-92-32-32-32-47-58-47-32-32-92-58-92-32-32-92-32-32-32-47-58-47-32-32-92-58-92-32-32-92-32-10-32-47-92-32-92-58-92-32-92-32-92-95-95-92-
32-47-58-47-32-124-58-58-58-58-92-95-95-92-32-47-58-47-92-58-92-32-92-58-92-95-95-92-32-47-58-47-95-95-47-32-92-58-92-95-95-92-32-47-58-47-
95-95-47-32-92-58-92-95-95-92-32-10-32-92-58-92-32-92-58-92-32-92-47-95-95-47-32-92-47-95-95-47-45-45-47-58-47-32-32-47-32-92-47-95-95-92-58-
92-47-58-47-32-32-47-32-92-58-92-32-32-92-32-47-58-47-32-32-47-32-92-58-92-32-32-92-32-47-58-47-32-32-47-32-10-32-32-92-58-92-32-92-58-92-95-
95-92-32-32-32-32-32-32-32-32-32-47-58-47-32-32-47-32-32-32-32-32-32-32-92-58-58-47-32-32-47-32-32-32-92-58-92-32-32-47-58-47-32-32-47-32-32-
32-92-58-92-32-32-47-58-47-32-32-47-32-10-32-32-32-92-58-92-47-58-47-32-32-47-32-32-32-32-32-32-32-32-47-58-47-32-32-47-32-32-32-32-32-32-32-
32-32-92-47-95-95-47-32-32-32-32-32-92-58-92-47-58-47-32-32-47-32-32-32-32-32-92-58-92-47-58-47-32-32-47-32-32-10-32-32-32-32-92-58-58-47-32-
32-47-32-32-32-32-32-32-32-32-47-58-47-32-32-47-32-32-32-32-32-32-32-32-32-32-32-32-32-32-32-32-32-32-32-32-32-92-58-58-47-32-32-47-32-32-32-
32-32-32-32-92-58-58-47-32-32-47-32-32-32-10-32-32-32-32-32-92-47-95-95-47-32-32-32-32-32-32-32-32-32-92-47-95-95-47-32-32-32-32-32-32-32-32-
32-32-32-32-32-32-32-32-32-32-32-32-32-32-32-92-47-95-95-47-32-32-32-32-32-32-32-32-32-92-47-95-95-47-32-32-32-32-10-10-32-32-32-32-32-32-32-
32-32-32-32-32-32-32-32-32-32-32-32-32-32-32-32-19978-28023-28145-26222-36719-20214-26377-38480-20844-21496-32-45-32-119-119-119-46-115-109-
112-111-111-46-99-111-109`;
const _strPowerBy = "80-111-119-101-114-32-98-121-32-116-70-114-97-109-101-32-118-49-48-32-45-32-116-104-101-32-101-110-103-105-110-32-111-102-32-116-67-111-102-102-101";
const _strAppCopy1 = "67-111-112-121-114-105-103-104-116-32-169-32-50-48-49-53-32-45-32";
const _strAppCopy2 = encode(`${( new Date()).getFullYear() - 1}`).join("-");
const _strAppCopy3 = "32-28145-26222-32-83-77-80-79-79-46-99-111-109-32-29256-26435-25152-26377";
const _strCompany = "19978-28023-28145-26222-36719-20214-26377-38480-20844-21496";
const _strWebSite = "119-119-119-46-115-109-112-111-111-46-99-111-109";
const rowBrand1 = "37-99-32-116-70-114-97-109-101-32-37-99-32-86-49-48-32-37-99-32-19978-28023-28145-26222-36719-20214-26377-38480-20844-21496-32-80-111-119-101-114-32-98-121-32-116-67-111-102-102-101-32-101-110-103-105-110-46";
const rowBrand2 = "112-97-100-100-105-110-103-58-32-50-112-120-32-49-112-120-59-32-98-111-114-100-101-114-45-114-97-100-105-117-115-58-32-51-112-120-32-48-32-48-32-51-112-120-59-32-99-111-108-111-114-58-32-35-102-102-102-59-32-98-97-99-107-103-114-111-117-110-100-58-32-35-54-48-54-48-54-48-59-32-102-111-110-116-45-119-101-105-103-104-116-58-32-98-111-108-100-59";
const rowBrand3 = "112-97-100-100-105-110-103-58-32-50-112-120-32-49-112-120-59-32-98-111-114-100-101-114-45-114-97-100-105-117-115-58-32-48-32-51-112-120-32-51-112-120-32-48-59-32-99-111-108-111-114-58-32-35-102-102-102-59-32-98-97-99-107-103-114-111-117-110-100-58-32-111-114-97-110-103-101-59-32-102-111-110-116-45-119-101-105-103-104-116-58-32-98-111-108-100-59";
const rowBrand4 = "112-97-100-100-105-110-103-58-32-50-112-120-32-49-112-120-59-32-98-111-114-100-101-114-58-32-110-111-110-101-59-32-99-111-108-111-114-58-32-35-52-52-52-59-32-98-97-99-107-103-114-111-117-110-100-58-32-116-114-97-110-115-112-97-114-101-110-116-59-32-102-111-110-116-45-119-101-105-103-104-116-58-32-49-48-48-59";
const brand = decode(_strBrand);
const brandRow = [decode(rowBrand1), decode(rowBrand2), decode(rowBrand3), decode(rowBrand4)];
class Smpoo {
  /** 公司名称
   *
   */
  static get company() {
    return decode(_strCompany);
  }
  /** copyright 版权字样
   *
   */
  static get copyRight() {
    return decode(`${_strAppCopy1}-${_strAppCopy2}-${_strAppCopy3}`);
  }
  /** Power by ... 字样
   *
   */
  static get powerBy() {
    return decode(_strPowerBy);
  }
  /** 深普域名
   *
   */
  static get website() {
    return decode(_strWebSite);
  }
  /** 打印深普标记到控制
   *
   * @param richText 若为 true，则显示字符图标LOGO，若为 false，则显示单行文字
   * @param bottomRows 正文输出后，与后续屏显内容的相隔行数
   * @param color 彩色输出，默认为单色
   * @param title 字符画左上角附加的主副标题
   */
  static printBrand(richText = true, bottomRows = 2, color = "none", title = []) {
    const arrStr = bottomRows ? new Array(bottomRows).fill("") : [];
    const msg = richText ? brand : brandRow;
    const [mainTitle = "\u6DF1\u666E", subTitle] = title;
    const [e] = ECHO_COLOR.end;
    if (color === "none") {
      if (mainTitle) {
        console.log(mainTitle);
        console.log("");
      }
      console.log(msg);
    } else {
      const [a, b] = ECHO_COLOR[`${color}BG`];
      const [c, d] = ECHO_COLOR[color];
      if (subTitle) {
        console.log(`${a} ${mainTitle} ${b} ${c} ${subTitle} ${d}`);
      } else {
        console.log(`${a} ${mainTitle} ${b}`);
      }
      console.log("");
      console.log(`${c} ${msg} ${d}`);
    }
    if (bottomRows) {
      console.log(`${arrStr.join("\n")}${e}`);
    } else {
      console.log(`${e}`);
    }
  }
}

const insertToArrIdx = (arr, item, destIdx) => {
  const a = arr.splice(0, destIdx);
  return [...a, item, ...arr];
};
const arrUnion = (...arr) => [...new Set(arr.flat())];
const arrIntersection = (...arr) => arr.reduce((a, b) => a.filter((c) => b.includes(c)));
const arrDiff = (...arr) => [...new Set(arr.flat())].filter((v) => arr.filter((vArr) => vArr.includes(v)).length === 1);

var ETYPE;
(function(ETYPE2) {
  ETYPE2["arguments"] = "arguments";
  ETYPE2["array"] = "array";
  ETYPE2["arraybuffer"] = "arraybuffer";
  ETYPE2["boolean"] = "boolean";
  ETYPE2["date"] = "date";
  ETYPE2["dataview"] = "dataview";
  ETYPE2["error"] = "error";
  ETYPE2["float32array"] = "float32array";
  ETYPE2["float64array"] = "float64array";
  ETYPE2["function"] = "function";
  ETYPE2["int16array"] = "int16array";
  ETYPE2["int32array"] = "int32array";
  ETYPE2["int8array"] = "int8array";
  ETYPE2["map"] = "map";
  ETYPE2["number"] = "number";
  ETYPE2["null"] = "null";
  ETYPE2["object"] = "object";
  ETYPE2["regexp"] = "regexp";
  ETYPE2["set"] = "set";
  ETYPE2["string"] = "string";
  ETYPE2["symbol"] = "symbol";
  ETYPE2["uint16array"] = "uint16array";
  ETYPE2["uint32array"] = "uint32array";
  ETYPE2["uint8array"] = "uint8array";
  ETYPE2["uint8clampedarray"] = "uint8clampedarray";
  ETYPE2["undefined"] = "undefined";
  ETYPE2["weakmap"] = "weakmap";
  ETYPE2["weakref"] = "weakref";
  ETYPE2["weakset"] = "weakset";
})(ETYPE || (ETYPE = {}));

const KEEP_UNIT = ["\u4E07", "\u4EBF", "\u5146", "\u4EAC", "\u5793", "\u677C", "\u7A70", "\u6C9F", "\u6DA7", "\u6B63"];
const ALIAS_NUM = ["\u96F6", "\u58F9", "\u8D30", "\u53C1", "\u8086", "\u4F0D", "\u9646", "\u67D2", "\u634C", "\u7396"];
const ALIAS_UNIT = ["\u5143", "\u62FE", "\u4F70", "\u4EDF", "\u4E07", "\u62FE", "\u4F70", "\u4EDF", "\u4EBF", "\u62FE", "\u4F70", "\u4EDF", "\u5146", "\u62FE", "\u4F70", "\u4EDF", "\u4EAC", "\u62FE", "\u4F70", "\u4EDF", "\u5793", "\u62FE", "\u4F70", "\u4EDF", "\u677C", "\u62FE", "\u4F70", "\u4EDF", "\u7A70", "\u62FE", "\u4F70", "\u4EDF", "\u6C9F", "\u62FE", "\u4F70", "\u4EDF", "\u6DA7", "\u62FE", "\u4F70", "\u4EDF", "\u6B63", "\u62FE", "\u4F70", "\u4EDF"];
const SHORT_NUM_UNIT = {
  \u4E07: 4,
  \u5341\u4E07: 5,
  \u767E\u4E07: 6,
  \u5343\u4E07: 7,
  \u4EBF: 8,
  \u5341\u4EBF: 9,
  \u767E\u4EBF: 10,
  \u5343\u4EBF: 11,
  \u5146: 12,
  \u4EAC: 16
};
const LARG_SIZE_VAL = [Math.pow(2, 70), Math.pow(2, 80), Math.pow(2, 90)];
const slitNumWithComma = (val, fracDigits = 2) => {
  return Intl.NumberFormat("en-US", {
    maximumFractionDigits: fracDigits
  }).format(val);
};
const getRoundNum = (val, digit = 2) => {
  return +Intl.NumberFormat("en-US", {
    maximumFractionDigits: digit
  }).format(val).replace(/,/g, "");
};
const numToCNY = function(val) {
  const ALIAS_FRA = ["\u89D2", "\u5206", "\u5398", "\u6BEB", "\u4E1D"];
  const [a, b] = `${val}`.split(".");
  if (a.length > ALIAS_UNIT.length)
    return "\u91D1\u989D\u8D85\u51FA\u6709\u6548\u8303\u56F4\uFF0C\u65E0\u6CD5\u663E\u793A\u5927\u5199";
  const _arr = [];
  const _arr2 = [];
  const _arrA = a.split("").reverse();
  _arrA.forEach((v, k) => {
    const u = ALIAS_UNIT[k];
    const numIntPart = `${ALIAS_NUM[+v]}${v !== "0" || KEEP_UNIT.includes(u) ? u : ""}`;
    _arr.push(numIntPart);
  });
  if (b && b.length) {
    const _arrB = b.split("").slice(0, ALIAS_FRA.length);
    const _arrFra = [];
    _arrB.forEach((v, k) => {
      _arrFra.push(`${ALIAS_NUM[+v]}${ALIAS_FRA[k]}`);
    });
    _arr2.push(_arrFra.join(""));
  } else {
    _arr2.push("\u6574");
  }
  return [_arr.reverse().join("").replace(new RegExp(KEEP_UNIT.map((v) => `(\u96F6+${v})`).join("|"), "g"), (a2) => {
    return a2.replace(/零+/g, "");
  }).replace(/零+/g, "\u96F6"), _arr2.join("")].join("");
};
const getMaxDigitLen = (...val) => {
  return Math.max(...val.map((v) => (`${v}`.split(".")[1] || "").length));
};
const transRadixFrom10 = (val, to) => {
  const newVal = +val;
  if (newVal) {
    return {
      val: Number(val).toString(to),
      type: typeof val === "number" ? ETYPE.number : ETYPE.string
    };
  } else {
    return {
      val: NaN,
      type: ETYPE.string
    };
  }
};
const sizeToByte = (originUnit) => {
  if (originUnit.toLowerCase() === "byte")
    return 1;
  const currUnit = originUnit.toUpperCase();
  const currPower = currUnit === "K" && 10 || currUnit === "M" && 20 || currUnit === "G" && 30 || currUnit === "T" && 40 || currUnit === "P" && 50 || currUnit === "E" && 60 || currUnit === "Z" && 70 || currUnit === "Y" && 80 || currUnit === "B" && 90 || 0;
  return Math.pow(2, currPower);
};
const getMatchUnit = (val) => {
  let currUnit = "Byte";
  if (val > 0) {
    currUnit = val < 1024 && "Byte" || val < 1048576 && "K" || val < 1073741824 && "M" || val < 1099511627776 && "G" || val < 1125899906842624 && "T" || val < 1152921504606847e3 && "P" || val < LARG_SIZE_VAL[0] && "E" || val < LARG_SIZE_VAL[1] && "Z" || val < LARG_SIZE_VAL[2] && "Y" || "B";
  }
  return currUnit;
};
const getSizeWithUnit = (val, unitFrom, unitTo, digitLen = 0, gapBy = " ") => {
  if (+val < 1 || !unitFrom)
    return `0${gapBy}Byte`;
  if (unitFrom === unitTo) {
    return `${getRoundNum(val, digitLen)}${gapBy}${unitFrom}`;
  }
  const valToByte = val * sizeToByte(unitFrom);
  const currUnitTo = unitTo || getMatchUnit(valToByte);
  return `${getRoundNum(valToByte / sizeToByte(currUnitTo), digitLen)}${gapBy}${currUnitTo}`;
};

const DICT_ANIMAL = "\u9F20\u725B\u864E\u5154\u9F99\u86C7\u9A6C\u7F8A\u7334\u9E21\u72D7\u732A";
const DICT_SIGN = "\u6469\u7FAF\u5B9D\u74F6\u53CC\u9C7C\u767D\u7F8A\u91D1\u725B\u53CC\u5B50\u5DE8\u87F9\u72EE\u5B50\u5904\u5973\u5929\u79E4\u5929\u874E\u5C04\u624B";
const DICT_GZ = ["\u7532\u4E59\u4E19\u4E01\u620A\u5DF1\u5E9A\u8F9B\u58EC\u7678", "\u5B50\u4E11\u5BC5\u536F\u8FB0\u5DF3\u5348\u672A\u7533\u9149\u620C\u4EA5"];
const DICT_LUNAR = ["\u5341", "\u4E00", "\u4E8C", "\u4E09", "\u56DB", "\u4E94", "\u516D", "\u4E03", "\u516B", "\u4E5D"];
const MIN_MAX = 864e13;
const DEFAULT_FMTSTR = "yyyy-mm-dd";
const WEEK_STR = {
  zh: ["\u5468\u65E5", "\u5468\u4E00", "\u5468\u4E8C", "\u5468\u4E09", "\u5468\u56DB", "\u5468\u4E94", "\u5468\u516D"],
  en: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]
};
const DAYS_MONTH = [31, 0, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
const MS_DAY = 24 * 60 * 60 * 1e3;
const MS_HOURE = 60 * 60 * 1e3;
const NUM_TO_STR = ["\u96F6", "\u4E00", "\u4E8C", "\u4E09", "\u56DB", "\u4E94", "\u516D", "\u4E03", "\u516B", "\u4E5D", "\u5341"];
const QUARTER_LIST = [[1, 2, 3], [4, 5, 6], [7, 8, 9], [10, 11, 12]];
const __fmtVal__ = function(val, fmt) {
  const __keepLen__ = (val2, len = 2) => `${val2}`.padStart(len, "0");
  const _y = `${val.getFullYear()}`;
  const _patternObj = {
    yyyy: _y,
    yy: __keepLen__(_y.slice(-2)),
    mm: __keepLen__(val.getMonth() + 1),
    dd: __keepLen__(val.getDate()),
    hh: __keepLen__(val.getHours()),
    mi: __keepLen__(val.getMinutes()),
    ss: __keepLen__(val.getSeconds()),
    ms: __keepLen__(val.getMilliseconds(), 3)
  };
  return (fmt?.toLowerCase() || DEFAULT_FMTSTR).replace(/yyyy|ms|dd|hh|mi|ss|mm/g, (a) => {
    return _patternObj[a || ""] || "";
  });
};
const __checkDate__ = (val) => {
  if (!(val.toString() === "Invalid Date")) {
    return new TDate(val);
  } else {
    throw new Error(`Get invalid param for fuction tdate. 
This parma can be null/undefind or datetime string, 
also can be number between -${MIN_MAX} AND < ${MIN_MAX}.
This function already return now as default.`);
  }
};
const __getLunarName__ = (data) => {
  const val = `${data}`;
  const [a, b] = val.split("").map((v) => +v);
  if (val === "10")
    return "\u521D\u5341";
  else if (val === "20")
    return "\u4E8C\u5341";
  else if (val === "30")
    return "\u4E09\u5341";
  else if (b) {
    const _str1 = a === 1 && "\u5341" || a === 2 && "\u5EFF" || "\u4E09\u5341";
    const _str2 = DICT_LUNAR[b];
    return `${_str1}${_str2}`;
  } else {
    return `\u521D${DICT_LUNAR[a]}`;
  }
};
class TDate {
  #val;
  constructor(initVal) {
    this.#val = initVal;
  }
  /** 判断当前实例所代表的日期是否为闰年
   * @returns 输入布尔值，Ture代表是，False代表否
   */
  get isLeap() {
    const y = this.#val.getFullYear();
    return y % 4 === 0 && y % 100 !== 0 || y % 400 === 0;
  }
  /** 获取时间戳的最大绝对值。
   *  时间戳的有效范围应该是正负（绝对值）区间
   * @returns 代表区间范围的绝对值（正负绝对值相同）
   */
  static get abs() {
    return MIN_MAX;
  }
  /** 获取实例日期所在年份
   * @returns 输出阿拉伯数字格式
   */
  get year() {
    return +this.format("yyyy");
  }
  /** 获取实例日期所在月份
   * @returns 输出阿拉伯数字格式
   */
  get month() {
    return +this.format("mm");
  }
  /** 获取实例日期的公历号数
   * @returns 输出阿拉伯数字格式
   */
  get day() {
    return +this.format("dd");
  }
  /** 获取实例日期的小时
   * @returns 输出阿拉伯数字格式
   */
  get hour() {
    return +this.format("hh");
  }
  /** 获取实例日期的分钟
   * @returns 输出阿拉伯数字格式
   */
  get minute() {
    return +this.format("mi");
  }
  /** 获取实例日期的秒数
   * @returns 输出阿拉伯数字格式
   */
  get second() {
    return +this.format("ss");
  }
  /** 获取实例日期的毫秒数
   * @returns 输出阿拉伯数字格式
   */
  get millisecond() {
    return +this.format("ms");
  }
  /** 获取实例日期是周几
   * @returns 输出阿拉伯数字代表的周（本周第几天，周一为1，周日为7）
   */
  get week() {
    return this.#val.getDay() || 7;
  }
  /** 获取实例日期的所在季度
   * @returns 输出阿拉伯数字代表的季度序号，起始为1
   */
  get quarter() {
    const m = this.#val.getMonth() + 1;
    return m < 4 && 1 || m < 7 && 2 || m < 10 && 3 || 4;
  }
  /** 获取实例日期对应的节气
   * @returns
   */
  get solar() {
    const [year, month, day] = this.toArr();
    let y = +year;
    let m = +month - 1;
    let d = +day;
    const sTermInfo = new Array(0, 21208, 42467, 63836, 85337, 107014, 128867, 150921, 173149, 195551, 218072, 240693, 263343, 285989, 308563, 331033, 353350, 375494, 397447, 419210, 440795, 462224, 483532, 504758);
    const solarTerm = new Array("\u5C0F\u5BD2", "\u5927\u5BD2", "\u7ACB\u6625", "\u96E8\u6C34", "\u60CA\u86F0", "\u6625\u5206", "\u6E05\u660E", "\u8C37\u96E8", "\u7ACB\u590F", "\u5C0F\u6EE1", "\u8292\u79CD", "\u590F\u81F3", "\u5C0F\u6691", "\u5927\u6691", "\u7ACB\u79CB", "\u5904\u6691", "\u767D\u9732", "\u79CB\u5206", "\u5BD2\u9732", "\u971C\u964D", "\u7ACB\u51AC", "\u5C0F\u96EA", "\u5927\u96EA", "\u51AC\u81F3");
    let solarTerms = "";
    while (solarTerms == "") {
      let tmp1 = new Date(315569259747e-1 * (y - 1900) + sTermInfo[m * 2 + 1] * 6e4 + Date.UTC(1900, 0, 6, 2, 5));
      let tmp2 = tmp1.getUTCDate();
      if (tmp2 == d)
        solarTerms = solarTerm[m * 2 + 1];
      tmp1 = new Date(315569259747e-1 * (y - 1900) + sTermInfo[m * 2] * 6e4 + Date.UTC(1900, 0, 6, 2, 5));
      tmp2 = tmp1.getUTCDate();
      if (tmp2 == d)
        solarTerms = solarTerm[m * 2];
      if (d > 1) {
        d = d - 1;
      } else {
        m = m - 1;
        if (m < 0) {
          y = y - 1;
          m = 11;
        }
        d = 31;
      }
    }
    return solarTerms;
  }
  /** 获取实例日期所对应的星座
   */
  get star() {
    const Zone = new Array(1222, 122, 222, 321, 421, 522, 622, 722, 822, 922, 1022, 1122, 1222);
    const [year, month, day] = this.toArr();
    const m = month;
    const d = day;
    if (100 * m + d >= Zone[0] || 100 * m + d < Zone[1]) {
      var i = 0;
    } else {
      for (var i = 1; i < 12; i++) {
        if (100 * m + d >= Zone[i] && 100 * m + d < Zone[i + 1]) {
          break;
        }
      }
    }
    return DICT_SIGN.substring(2 * i, 2 * i + 2);
  }
  /** 获取实例日期所对应的属相
   */
  get animal() {
    return DICT_ANIMAL.charAt((this.#val.getFullYear() - 4) % 12);
  }
  /** 获取实例日期是所在季度的第几天
   * @returns 输出阿拉伯数字代表的周（起始为1）
   */
  get indexOfQuarter() {
    return -1 * this.getDiff(/* @__PURE__ */ new Date(`${this.year}-${[1, 4, 7, 10][this.quarter - 1]}-1 00:00:00.000`), "day");
  }
  /** 获取实例日期是所在年份的第几天
   * @returns 输出阿拉伯数字代表的周（起始为1）
   */
  get indexOfYear() {
    return getRoundNum((this.toNumber() - (/* @__PURE__ */ new Date(`${this.year}-1-1 00:00:00.000`)).getTime()) / MS_DAY, 0);
  }
  /** 获取实例日期所在月份的总天数
   */
  get daysOfMonth() {
    return new Date(this.#val.getFullYear(), +this.format("mm"), 0).getDate();
  }
  /** 获取实例日期所在季度的总天数
   */
  get daysOfQuarter() {
    const daysOfMonth2 = new Date(this.year, 2, 0).getDate();
    return [
      [0, 1, 2],
      [3, 4, 5],
      [6, 7, 8],
      [9, 10, 11]
    ][this.quarter - 1].map((v) => DAYS_MONTH[v] || daysOfMonth2).reduce((pre, curr) => {
      return pre + curr;
    }, 0);
  }
  /** 获取实例日期所在年份的总天数
   * @returns
   */
  get daysOfYear() {
    return 337 + new Date(this.#val.getFullYear(), 2, 0).getDate();
  }
  /** 获取实例日期相比所在周的百分比占比
   * @returns
   */
  get ratioOfWeek() {
    return getRoundNum(this.week / 7, 1);
  }
  /** 获取实例日期相比所在月的百分比占比
   * @returns
   */
  get ratioOfMonth() {
    return getRoundNum(this.day / this.daysOfMonth, 1);
  }
  /** 获取实例日期相比所在季度的百分比占比
   * @returns
   */
  get ratioOfQuarter() {
    return getRoundNum(this.indexOfQuarter / this.daysOfQuarter, 1);
  }
  /** 获取实例日期相比所在年份的百分比占比
   * @returns
   */
  get ratioOfYear() {
    return getRoundNum(this.indexOfYear / this.daysOfYear, 3);
  }
  /** 获取实例日期对应的天干纪年法
   */
  get tiangan() {
    const i = this.#val.getFullYear() - 1900 + 36;
    return DICT_GZ[0].charAt(i % 10) + DICT_GZ[1].charAt(i % 12);
  }
  /** 获取实例日期属于本月第几周
   * @returns 输出阿拉伯数字代表的月内周次序号，起始为1
   */
  get weekOfMonth() {
    return Math.ceil((this.#val.getDate() + 6 - this.#val.getDay()) / 7);
  }
  /** 获取实例日期属于当年第几周
   * @returns 输出阿拉伯数字代表的年内周次序号，起始为1
   */
  get weekOfYear() {
    return Math.ceil((this.#val.getDate() + 6 - this.#val.getDay()) / 7);
  }
  /** 将指定日期按照提供的模式匹配字符串格式化
   * @param {*} fmt 用于格式化的模式匹配字符串，为空时默认为 'yyyy-mm-dd'
   * @returns 已格式化的时间 / 日期 字符串（阿拉伯数字形式）
   */
  format = (fmt) => {
    return __fmtVal__.call(this, this.#val, fmt || "yyyy-mm-dd");
  };
  /** 将指定日期按照提供的模式匹配字符串格式化为中文汉字输出
   * @param {*} withYear 是否输出年份，默认为否
   * @param {*} withTime 是否输出时间信息
   * @returns 已格式化的时间 / 日期 字符串（中文汉字形式）
   */
  formatAsCn = (withYear = false, withTime) => {
    const [a, b] = Intl.DateTimeFormat("zh-u-nu-hanidec", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "numeric",
      second: "numeric",
      hour12: false,
      timeZone: "Asia/Shanghai"
    }).format(this.#val).split(/\s+/);
    const [y, m, d] = a.split("/");
    const rplc = (str) => str.replace(/^〇〇$/, "\u96F6").replace(/^〇/, "").replace(/〇$/, "\u5341");
    const _dtPart = withYear ? `${y}\u5E74${rplc(m)}\u6708${rplc(d)}\u65E5` : `${rplc(m)}\u6708${rplc(d)}\u65E5`;
    if (!withTime) {
      return _dtPart;
    } else {
      const _tmPart = b.split(":").map((v) => rplc(v)).join(":");
      return `${_dtPart} ${_tmPart}`;
    }
  };
  /** 将指定日期格式化为农历表示法
   * @param {boolean} skipYear 是否省略年份信息
   * @returns 已格式化的农历日期
   */
  formatAsLunar = (skipYear = true) => {
    const _val = Intl.DateTimeFormat("zh-u-ca-chinese-nu-latn").format(this.#val);
    if (!skipYear) {
      const [a, b] = _val.split("\u6708");
      return `${a}\u6708${__getLunarName__(b)}`;
    } else {
      const _arr = _val.split("\u5E74");
      const [a, b] = (_arr[1] || "").split("\u6708");
      return `${a}\u6708${__getLunarName__(b)}`;
    }
  };
  /** 获取实例日期的佛历表示法
   * @returns 已格式化的佛历日期
   */
  formatAsBh = () => {
    return Intl.DateTimeFormat("zh-chinese-u-ca-buddhist").format(this.#val).replace(/-/, "\u5E74").replace(/-/, "\u6708");
  };
  /** 按照指定语言环境字符串标签格式化日期（语言环境字符串标签参考：Intl.DateTimeFormat 的 参数）
   * @param {*} languageTag 语言环境字符串，默认为 加拿大法文格式：YYYY-MM-DD
   * @returns 已格式化的字符串
   */
  formatAsWorld = (languageTag) => {
    return Intl.DateTimeFormat(languageTag || "fr-ca").format(this.#val);
  };
  /** 获取实例日期的周信息
   * @param local [可选]，代表返回数据采用的区域信息
   * @returns 若传入参数为空，则输出阿拉伯数字代表的周（本周第几天，周一为1，周日为7），若传入参数不为空，则返回字符化的周信息。
   */
  getWeek = (local) => {
    const w = this.#val.getDay();
    const str = local || "zh";
    if (typeof local === "undefined") {
      return w || 7;
    } else {
      return WEEK_STR[str][w];
    }
  };
  /** 获取实例日期属于本月第几周
   * @param local [可选]，代表返回数据采用的区域信息
   * @returns 若传入参数为空，则输出阿拉伯数字代表的月内周次序号，起始为1，若传入参数不为空，则返回字符化的周信息。
   */
  getWeekOfMonth = (local) => {
    const w = Math.ceil((this.#val.getDate() + 6 - this.#val.getDay()) / 7);
    if (typeof local !== "undefined") {
      return NUM_TO_STR[w];
    } else {
      return w;
    }
  };
  /** 获取实例日期属于当年第几周
   * @param local [可选]，代表返回数据采用的区域信息
   * @returns 若传入参数为空，则输出阿拉伯数字代表的年内周次序号，起始为1，若传入参数不为空，则返回字符化的周信息。
   */
  getWeekOfYear = () => {
    return getRoundNum((this.indexOfYear - (7 - ((/* @__PURE__ */ new Date(`${this.year}-1-1`)).getDay() || 7))) / 7, 0) + 1;
  };
  /** 获取实例日期的所在季度
   * @param local [可选]，代表返回数据采用的区域信息
   * @returns 若传入参数为空，则输出阿拉伯数字代表的季度序号，起始为1，若传入 zh ，则将数字中文字符化
   */
  getQuarter = (local) => {
    const m = this.#val.getMonth() + 1;
    const q = m < 4 && 1 || m < 7 && 2 || m < 10 && 3 || 4;
    if (typeof local !== "undefined") {
      return `${NUM_TO_STR[q]}\u5B63\u5EA6`;
    } else {
      return q;
    }
  };
  /** 获取相对于实例日期，指定单位数量（天、周、月、年）之前或之后的日期值
   *
   * @param diffNum 与实例日期间相差的数量（默认单位为天），为正则返回日期在实例日期之后，反之则在实例日期之前
   * @param diffType 相对于实例日期，相差数量的日期单位
   */
  getOffset = (diffNum, diffType) => {
    if (!diffNum || typeof diffNum === "undefined" || diffType === "none")
      return this.format();
    let val = diffNum * MS_DAY;
    if (diffType === "quarter") {
      const [m] = QUARTER_LIST[this.quarter - 1];
      return [this.year, `${m}`.padStart(2, "0"), "01"].join("-");
    } else {
      if (diffType === "week") {
        val *= 7;
      } else if (diffType === "month") {
        val *= 30;
      } else if (diffType === "year") {
        val *= 365;
      } else if (diffType === "hour") {
        val = MS_HOURE * diffNum;
      }
      return Intl.DateTimeFormat("fr-ca").format(new Date(this.#val.getTime() + val));
    }
  };
  /** 比较两个日期数据，并返回相差数量，单位为（天、周、月、年）
   *
   * @param start 要比较的基准日期
   * @param end 要比较的目标日期
   * @param outputType 返回值所使用的日期单位，默认为天
   */
  getDiff = (dateVal, outputType) => {
    if (typeof dateVal !== "undefined") {
      const num1 = this.#val.getTime();
      const tp = typeof dateVal;
      const num2 = tp === "number" && dateVal || (dateVal instanceof Date && dateVal || typeof dateVal === "string" && new Date(dateVal) || dateVal.val || this.#val).getTime();
      const diffVal = num2 - num1;
      const diffValDay = Math.round((num2 - num1) / 1e3 / 60 / 60 / 24);
      const getNum = (val, len = 1) => +val.toFixed(len);
      if (outputType === "ms") {
        return diffVal;
      } else if (outputType === "second") {
        return diffVal / 1e3 << 0;
      } else if (outputType === "minute") {
        return diffVal / 1e3 / 60 << 0;
      } else if (outputType === "hour") {
        return getNum(diffVal / 1e3 / 60 / 60, 1);
      } else if (outputType === "year") {
        return +(diffValDay / 365).toFixed(3);
      } else if (outputType === "month") {
        return getNum(diffValDay / 30);
      } else if (outputType === "week") {
        return getNum(diffValDay / 7);
      } else {
        return diffValDay;
      }
    } else {
      return 0;
    }
  };
  /** 将实例日期的值转换为数字
   *
   * @param notTimestamp 是否弃用时间戳形式而改为用户序列值，默认为否。为真时，可以自定义数字来源中包含的日期时间片段
   * @param fmt 自定义数字来源片段的格式化字符串，仅当 notTimestamp 为真时有效
   * @returns
   */
  toNumber = (notTimestamp, fmt) => {
    if (notTimestamp) {
      const val = +this.format((fmt || "yyyymmddhhmissms").replace(/-/g, ""));
      if (!isNaN(val)) {
        return val;
      } else {
        throw new Error("Get invalid param of tdate.toNumber.\nIn this function's param string, you should with the pattern string like tdate.format only.");
      }
    } else {
      return this.#val.getTime();
    }
  };
  /** 获取实例日期的 JSON 对象表示
   * @param local [可选]，代表返回数据采用的区域信息
   * @returns 格式化后的 JSON 对象
   */
  toJson = (local) => {
    const [y, m, d, h, mi, s, ms] = this.toArr(true);
    return {
      year: y,
      month: m,
      day: d,
      hour: h,
      minutes: mi,
      second: s,
      millisecond: ms,
      week: this.getWeek(local),
      weekOfMonth: this.getWeekOfMonth(local),
      weekOfYear: this.getWeekOfYear(),
      quarter: this.getQuarter(local),
      isLeap: this.isLeap,
      lunar: this.formatAsLunar(),
      buddhist: this.formatAsBh()
    };
  };
  /** 将实例日期转换为值数组
   * @param {*} includTime [可选]是否包含时间信息
   * @returns 不传入参数时，默认返回数组内元素依次为 [年，月，日，时，分，秒，毫秒]
   */
  toArr = (includTime) => {
    return this.format(includTime ? "yyyy-mm-dd-hh-mi-ss-ms" : "yyyy-mm-dd").split("-").map((v) => +v);
  };
}
function tDate(...val) {
  const [a, b, c, d = 0, e = 0, f = 0, g = 0] = val;
  if (!a) {
    return __checkDate__(/* @__PURE__ */ new Date());
  } else {
    if (val.length > 1) {
      return __checkDate__(new Date(a, b, c, d, e, f, g));
    } else {
      return __checkDate__(new Date(a));
    }
  }
}

const joinAsCamel = (str, symbolStr) => {
  return (Array.isArray(str) ? str : str.split(symbolStr || "_")).map((v, k) => {
    return !k ? v : upFirst(v);
  }).join("");
};
const splitCamelCase = (str, symbolStr) => {
  return str.replace(/[A-Z]/g, ($1) => {
    return `${symbolStr || "-"}${$1.toLowerCase()}`;
  });
};
const upFirst = (str) => {
  const [a, ...b] = str.split("");
  return [a.toUpperCase(), ...b].join("");
};

const emptyOrmBase = () => ({
  id: -1,
  pid: -1,
  code: "",
  namezh: "",
  memo: ""
});
const getName = (ormCode, ormGroup, nameType) => {
  const codeStr = `${ormGroup}_${splitCamelCase(ormCode, "_")}`;
  return nameType === "viewList" && `view_${codeStr}_list` || nameType === "viewMaster" && `view_${codeStr}_master` || nameType === "viewObj" && `view_${codeStr}_obj` || nameType === "viewPull" && `view_${codeStr}_pull` || nameType === "viewPush" && `view_${codeStr}_push` || nameType === "table" && codeStr || ormCode;
};
class OrmBaseData {
  #payload;
  ormCode;
  ormGroup;
  tablName;
  viewNameList;
  viewNameObj;
  viewNameMaster;
  viewNamePull;
  viewNamePush;
  constructor(ormCode, group, payload) {
    this.ormCode = ormCode;
    this.#payload = Object.assign(this.empty, payload || {});
    this.ormGroup = group;
    this.tablName = getName(ormCode, group, "table");
    this.viewNameList = getName(ormCode, group, "viewList");
    this.viewNameObj = getName(ormCode, group, "viewObj");
    this.viewNameMaster = getName(ormCode, group, "viewMaster");
    this.viewNamePull = getName(ormCode, group, "viewPull");
    this.viewNamePush = getName(ormCode, group, "viewPush");
  }
  /** 获取基于该实例模板的空白数据载荷 */
  get empty() {
    return emptyOrmBase();
  }
  /** 获取当前实例的数据载荷 */
  get payLoad() {
    return this.#payload || emptyOrmBase();
  }
  /** 获取实例对应的 ORM 模型代码 */
  getOrmCode() {
    return this.ormCode;
  }
  /** 获取实例对应的业务分组名称 */
  getOrmGroup() {
    return this.ormGroup;
  }
  /** 获取实例对应的数据表名称 */
  getTablName() {
    return this.tablName;
  }
  /** 获取实例对应的列表查询视图名称 */
  getViewNameList() {
    return this.viewNameList;
  }
  /** 获取实例对应的对象查询视图名称 */
  getViewNameObj() {
    return this.viewNameObj;
  }
  /** 获取实例对应的主数据查询视图名称 */
  getViewNameMaster() {
    return this.viewNameMaster;
  }
  /** 获取实例对应的上引视图名称 */
  getViewNamePull() {
    return this.viewNamePull;
  }
  /** 获取实例对应的下推视图名称 */
  getViewNamePush() {
    return this.viewNamePush;
  }
}

export { ECHO_COLOR, OrmBaseData, SHORT_NUM_UNIT, Smpoo, arrDiff, arrIntersection, arrUnion, decode, decodeBase64, emptyOrmBase, encode, encodeBase64, getMaxDigitLen, getRoundNum, getSizeWithUnit, insertToArrIdx, joinAsCamel, numToCNY, slitNumWithComma, splitCamelCase, tClear, tDate, tEcho, tLine, tLog, tRow, transRadixFrom10, upFirst };
