
/** sessionStorage 中的命名键名 */

/** MPA 多页跳转前的URL地址，用于切换时跳回 */
export const KEY_URL_FROM = 'urlFrom';

/** 用户信息在 sessionStorage 中的键名称 */
export const KEY_USER_CACHE = 'USER_INFO';

/** 用户信息在 sessionStorage 中的键名称 */
export const KEY_MENU_CACHE = 'userMenus';
export const KEY_MENU_CACHE_FLAT = 'userMenusFlat';
/** 当前打开窗口的 tabs 集合缓存 */
export const KEY_OPEN_PAGES = 'openPages';
/** 当前打开窗口的 tabs 的活跃索引 */
export const KEY_ACTIVE_PAGE = 'activePage';
/** 对 useNav 的缓存 */
export const KEY_STORE_NAV = 'storeNav';

/** TOKEN 有效期限定值在 sessionStorage 中的键名称 */
export const KEY_EXPIRED = 'expired';

/** token 信息在 sessionStorage 中的键名称 */
export const KEY_TOKEN = 'token';

/** 单据复制的来源ID在 sessionStorage 中的键名称 */
export const CLONE_KEY = 'cloneId';
/** 中国省份城市区县缓存数据 */
export const KEY_CHINA_CITYS = 'chinaCitys';
/** 产品标准价缓存 */
export const KEY_PROD_PRICE_CACHE = 'prodStandardPrice';
/** 跳转到首页的命名导航路由名称 */
export const HOME_KEY = 'ViewHome';
/** 系统全局业务参数缓存键 */
export const KEY_GLOBAL_BIZ_PARAM = 'globalSysBizParam';
/** 用户级列定义缓存键 */
export const COL_CUSTOMER_DEF = 'colCustomerDef';
