import type { IProductCacheItem, IUseCache, THookUseCache } from '@/types/hook/useCache';
import type { ICounterCache, IKvTreeRes, IMenuItem, IOrmKvDict, IStoreCache, TCounterPartSelectRange, TTreeCacheType, biz } from 'shangshi_types';
import { apiGetKvSource, apiGetBizParam, apiGetCounterTree, apiGetProductTree, apiGetStoreTree, apiGetProductList } from '@/util/useApi';
import { KEY_GLOBAL_BIZ_PARAM, KEY_MENU_CACHE_FLAT } from '@/constant/sessionKey';
import { delCache, getCache, setCache } from '@/util/cacheKit/getCache';
import { getKvKeyAlias } from 'shangshi_bizproto';

const KV_TREE = {
  counter: 'masterCounterparty_tree',
  product: 'masterProductMgr_tree',
  store: 'masterWarehouseMgr_tree'
};
const getKeyAliasMap = (key: string): string => `${getKvKeyAlias(key)}_map`;

const kvSourceCache: Map<string, IOrmKvDict[]> = new Map();
const kvSourceMapCache: Map<string, IOrmKvDict> = new Map();
const kvSourceTree: Map<string, TTreeCacheType[]> = new Map();
const sysBizParamCache: Map<string, biz.setting.sysSetting.ISysBizParam> = new Map();
const menuCache: Record<string, IMenuItem> = {};
const counterRoot: Map<TCounterPartSelectRange, number[]> = new Map();

/** 从集合中过滤出符合筛选条件的物料
 *
 * @param searchKey 筛选关键词
 * @param arr 集合
 * @returns
 */
const getMatchProduct = (searchKey: string, arr: IProductCacheItem[]): IProductCacheItem[] => {
  const resArr: IProductCacheItem[] = [];
  for (const v of arr) {
    const spec = ((v as IProductCacheItem).spec || '').toLowerCase();
    const prodIdNamezh = ((v as IProductCacheItem).prodIdNamezh || '').toLowerCase();
    if ((spec === searchKey) || (prodIdNamezh === searchKey)) {
      resArr.push(v);
      break;
    } else if (spec.includes(searchKey) || prodIdNamezh.includes(searchKey)) {
      resArr.push(v);
    }
  }
  return resArr;
};

/** hook_入口文件 */
export const useCache: THookUseCache = (): IUseCache => {
  /** 刷新系统设置中的业务参数缓存 */
  const refeshSysBizParams = async (): Promise<void> => {
    await delCache('kvSource', KEY_GLOBAL_BIZ_PARAM);
    sysBizParamCache.delete(KEY_GLOBAL_BIZ_PARAM);
    const cache = await apiGetBizParam();
    await setCache('kvSource', KEY_GLOBAL_BIZ_PARAM, cache);
    sysBizParamCache.set(KEY_GLOBAL_BIZ_PARAM, cache);
  };

  /** 获取系统设置中的业务参数 */
  const getSysBizParams = async (): Promise<biz.setting.sysSetting.ISysBizParam> => {
    const sysBizParam: biz.setting.sysSetting.ISysBizParam | undefined = sysBizParamCache.get(KEY_GLOBAL_BIZ_PARAM);
    if (sysBizParam) return sysBizParam;

    const cache = await getCache('kvSource', KEY_GLOBAL_BIZ_PARAM);
    if (cache) {
      sysBizParamCache.set(KEY_GLOBAL_BIZ_PARAM, cache);
      return cache;
    } else {
      const cacheRmt = await apiGetBizParam();
      sysBizParamCache.set(KEY_GLOBAL_BIZ_PARAM, cacheRmt);
      await setCache('kvSource', KEY_GLOBAL_BIZ_PARAM, cacheRmt);
      return cacheRmt;
    }
  };

  /** 获取当前已登录用户的菜单缓存 */
  const getMenus = (): Record<string, IMenuItem> => {
    if (!Object.keys(menuCache).length) {
      const cacheStr = sessionStorage.getItem(KEY_MENU_CACHE_FLAT);
      if (cacheStr) {
        const arr = Object.entries(JSON.parse(cacheStr));
        for (const v of arr) {
          const [a, b] = v;
          menuCache[a] = b as IMenuItem;
        }
      }
    }
    return menuCache;
  };

  /** 从缓存或远端获取列表形态的 kv 数据
   *
   * @param dictKeys 字典 key
   * @param masterKeys 主数据 key
   * @returns
   */
  const getKvSourceList = async (dictKeys: string[], masterKeys: string[]): Promise<Record<string, IOrmKvDict[]>> => {
    const obj: Record<string, IOrmKvDict[]> = {};
    const keyAlias: Record<string, string> = {};

    const unCachedDictKeys: string[] = [];
    const unCachedMasterKeys: string[] = [];
    for (const v of dictKeys) {
      const cacheKey = getKvKeyAlias(v);
      keyAlias[v] = cacheKey;
      const dict: IOrmKvDict[] | undefined = kvSourceCache.get(cacheKey);  // 优先读取内存缓存
      if (dict) {
        obj[v] = dict;
      } else {
        const cache = await getCache('kvSource', cacheKey);  // 若内存缓存不存在，则读取浏览器缓存
        if (cache) {
          kvSourceCache.set(cacheKey, cache);
          obj[v] = cache;
        } else { // 若浏览器缓存也不存在，则记录远端读取参数
          unCachedDictKeys.push(v);
        }
      }
    }
    for (const v of masterKeys) {
      const cacheKey = getKvKeyAlias(v);
      keyAlias[v] = cacheKey;
      const master: IOrmKvDict[] | undefined = kvSourceCache.get(cacheKey);  // 优先读取内存缓存
      if (master) {
        obj[v] = master;
      } else {
        const cache = await getCache('kvSource', cacheKey);  // 若内存缓存不存在，则读取浏览器缓存
        if (cache) {
          kvSourceCache.set(cacheKey, cache);
          obj[v] = cache;
        } else { // 若浏览器缓存也不存在，则记录远端读取参数
          unCachedMasterKeys.push(v);
        }
      }
    }
    if (unCachedDictKeys.length || unCachedMasterKeys.length) { // 发起远端请求
      const cacheRmt: Record<string, IOrmKvDict[]> = await apiGetKvSource(unCachedDictKeys, unCachedMasterKeys);
      const arrKeys = [...unCachedDictKeys, ...unCachedMasterKeys];
      for (const v of arrKeys) {
        const cacheKey = keyAlias[v];
        kvSourceCache.set(cacheKey, cacheRmt[cacheKey]); // 写入内存缓存
        await setCache('kvSource', cacheKey, cacheRmt[cacheKey]);  // 写入浏览器缓存
        obj[v] = cacheRmt[cacheKey];
      }
    }

    return obj;
  };

  /** 从缓存或远端获取关键词过滤的物料主数据的扁平备选集合
   *
   * @param key 主数据 key
   * @returns
   */
  const getProductBySearchKey = async (key?: string): Promise<IProductCacheItem[]> => {
    const cacheKey = KV_TREE.product;
    const searchKey = (key || '').toLowerCase();
    const kv: IOrmKvDict[] | undefined = kvSourceCache.get(cacheKey);
    if (!kv) {
      const cacheKv = await getCache('kvSource', cacheKey);
      if (cacheKv) {
        kvSourceCache.set(cacheKey, cacheKv);
        if (!key) return cacheKv;
        return getMatchProduct(searchKey, cacheKv);
      } else {
        const treeList = ((await apiGetProductList()) || []);
        kvSourceCache.set(cacheKey, treeList as IOrmKvDict[]);
        await setCache('kvSource', cacheKey, treeList);
      }
    }
    const arr = kvSourceCache.get(cacheKey) as unknown[];
    if (!key) return arr as IProductCacheItem[];
    return getMatchProduct(searchKey, arr as IProductCacheItem[]);
  };

  /** 从缓存或远端获取键值对形态的 kv 主数据
   *
   * @param key 主数据 key
   * @returns
   */
  const getKvSourceMap = async (key: string): Promise<IOrmKvDict> => {
    const cacheKey = getKeyAliasMap(key);
    const kv: IOrmKvDict | undefined = kvSourceMapCache.get(cacheKey);
    if (kv) return kv;
    const cacheKv = await getCache('kvSource', cacheKey);
    if (cacheKv) {
      kvSourceMapCache.set(cacheKey, cacheKv);
      return cacheKv;
    }
    const arr: IOrmKvDict[] = (await getKvSourceList([], [key]))?.[key] || [];
    const obj: IOrmKvDict | undefined = arr.find((v: IOrmKvDict) => v.key === key);
    // kvSourceMapCache.set(cacheKey, obj);
    return obj || {} as IOrmKvDict;
  };

  const getCounterByRange = (list: ICounterCache[], selectRange?: TCounterPartSelectRange): ICounterCache[] => {
    if (!selectRange) return list;
    const arr = counterRoot.get(selectRange) || [];
    return list.filter((v: ICounterCache) => arr.includes(v.id));
  };

  /** 从缓存或远端获取树状形态往来户的 kv 主数据
   *
   * @param key 主数据 key
   */
  const getKvTreeCounter = async (selectRange?: TCounterPartSelectRange): Promise<ICounterCache[]> => {
    const cacheKey = KV_TREE.counter;
    const kv: ICounterCache[] | undefined = kvSourceTree.get(cacheKey);
    if (kv) return kv;
    const cacheKv = await getCache('kvSource', cacheKey);
    const cacheCounterRoot = await getCache('counterRoot', (selectRange || 'all') as TCounterPartSelectRange);
    if (cacheKv && cacheCounterRoot) {
      kvSourceTree.set(cacheKey, cacheKv);
      return getCounterByRange(cacheKv, selectRange);
    }

    const { treeList, treeRoots = {} } = (await apiGetCounterTree()) || {} as IKvTreeRes;
    kvSourceTree.set(cacheKey, treeList);
    await setCache('kvSource', cacheKey, treeList);
    const arrRoots = Object.entries(treeRoots);
    for (const v of arrRoots) {
      const [a, b] = v as [TCounterPartSelectRange, number[]];
      counterRoot.set(a, b);
    }
    return getCounterByRange(treeList, selectRange);
  };

  /** 从缓存或远端获取树状形态往来户的 kv 主数据 */
  const getKvTreeProduct = async (): Promise<IProductCacheItem[]> => {
    const cacheKey = KV_TREE.product;
    const kv = kvSourceTree.get(cacheKey) as undefined | unknown;
    if (kv) return kv as IProductCacheItem[];
    const cacheKv = await getCache('kvSource', cacheKey);
    if (cacheKv) {
      kvSourceTree.set(cacheKey, cacheKv);
      return cacheKv;
    }
    const { treeList } = (await apiGetProductTree());
    kvSourceTree.set(cacheKey, treeList as TTreeCacheType[]);
    await setCache('kvSource', cacheKey, treeList);
    return treeList as IProductCacheItem[];
  };

  /** 从缓存或远端获取树状形态往来户的 kv 主数据 */
  const getKvTreeStore = async (): Promise<IStoreCache[]> => {
    const cacheKey = KV_TREE.store;
    const kv: IStoreCache[] | undefined = kvSourceTree.get(cacheKey);
    if (kv) return kv;
    const cacheKv = await getCache('kvSource', cacheKey);
    if (cacheKv) {
      kvSourceTree.set(cacheKey, cacheKv);
      return cacheKv;
    }
    const { treeList } = (await apiGetStoreTree()) || {} as IKvTreeRes;
    kvSourceTree.set(cacheKey, treeList);
    await setCache('kvSource', cacheKey, treeList);
    return treeList;
  };

  // /** 从缓存或远端获取指定业务唯一标识的视图字段定义 */
  // const getColDefs = async (imt: IDefImt, opt: IColDefOption): Promise<IViewDefinedExt[]> => {
  //   const bizIdent = imt.bizIdent;
  //   const colDefs: IViewDefinedExt[] | undefined = colDefsCache.get(bizIdent);
  //   if (colDefs) return colDefs;

  //   const cache = await getCache('colDefs', bizIdent);
  //   if (cache) {
  //     colDefsCache.set(bizIdent, cache);
  //     return cache;
  //   } else {
  //     const cacheRmt = getFendViewDef(imt);
  //     const cacheRmtExt = initColDef(imt, cacheRmt, opt);
  //     await setCache('colDefs', bizIdent, cacheRmtExt);
  //     colDefsCache.set(bizIdent, cacheRmtExt);
  //     return cacheRmtExt;
  //   }
  // };

  // /** 从缓存或远端获取指定业务唯一标识的视图字段定义 */
  // const getColDtlDefs = async (imt: IDefImt, dtlIdx: number, opt: IColDefOption): Promise<ITblHeaderExt[]> => {
  //   const bizIdentDtl = imt.bizIdentDtls[dtlIdx];
  //   if (!bizIdentDtl) return [];
  //   const colDefs: ITblHeaderExt[] | undefined = colDefsDtlCache.get(bizIdentDtl);
  //   if (colDefs) return colDefs;

  //   const cache = await getCache('colDefs', bizIdentDtl);
  //   if (cache) {
  //     colDefsDtlCache.set(bizIdentDtl, cache);
  //     return cache;
  //   } else {
  //     const cacheRmt = getFendViewDef(imt);
  //     const cacheRmtExt = Object.fromEntries(initColDef(imt, cacheRmt, opt).map((v: IViewDefinedExt) => [v.code, v]));

  //     const headers = (imt.tblHeaderDtl || []) as ITblHeader[];
  //     const arr: ITblHeaderExt[] = headers.map((v: ITblHeader): ITblHeaderExt => {
  //       const { dataIndex } = v;
  //       const coldef = (v as ITblHeaderExt).colDefs;

  //       const colKey = dataIndex || coldef?.code || v.slotName;
  //       v.slotName = dataIndex;
  //       if (colKey && cacheRmtExt[colKey]) {
  //         (v as ITblHeaderExt).colKey = colKey;
  //         (v as ITblHeaderExt).colDefs = cacheRmtExt[colKey] || { formatRule: {} };
  //         (v as ITblHeaderExt).colDefs!.formatRule.disable = !!(imt.defaultDisable?.dtl?.[colKey]);
  //         const disableEffectAll = typeof opt?.disableSet === 'boolean';
  //         (v as ITblHeaderExt).stopRender = (coldef?.uiEditorType === 'span')
  //           || (disableEffectAll && !!(opt.disableSet as boolean))
  //           || (!disableEffectAll && (opt.disableSet as Record<string, boolean>)?.[colKey]);
  //         v.align = (v as ITblHeaderExt).colDefs?.uiEditorType === 'numberInput' ? 'right' : 'left';
  //       }
  //       return v as ITblHeaderExt;
  //     });

  //     arr.unshift(
  //       { slotName: '$rowIndex', colKey: '$rowIndex', title: '行号', align: 'center', width: 30, fixed: 'left' },
  //       { slotName: '$rowOpt', colKey: '$rowOpt', title: '', align: 'center', width: 72, fixed: 'left' }
  //     );

  //     await setCache('colDefs', bizIdentDtl, arr);
  //     colDefsDtlCache.set(bizIdentDtl, arr);
  //     return arr;
  //   }
  // };

  // /** 从缓存或远端获取指定业务唯一标识的上引下推定义 */
  // const getPushPullDef = async (bizident: string): Promise<IInitDefPushPull> => {
  //   const pullPushDef: IInitDefPushPull | undefined = pullPushDefsCache.get(bizident);
  //   if (pullPushDef) return pullPushDef;

  //   const cache = await getCache('pullPulDefine', bizident);
  //   if (cache) {
  //     pullPushDefsCache.set(bizident, cache);
  //     return cache;
  //   } else {
  //     const cacheRmt = await apiGetPushPullDef(bizident);
  //     await setCache('pullPulDefine', bizident, cacheRmt);
  //     pullPushDefsCache.set(bizident, cacheRmt);
  //     return cacheRmt;
  //   }
  // };

  /** 刷新本地的指定键值缓存 */
  const refrashCache = async (key: string) => {
    const cacheKey = ((key === 'counter') && 'masterCounterparty')
      || ((key === 'product') && 'masterProductMgr')
      || ((key === 'store') && 'masterWarehouseMgr')
      || getKvKeyAlias(key);

    const cacheKeyMap = ((key === 'counter') && getKeyAliasMap(key))
      || ((key === 'product') && getKeyAliasMap(key))
      || ((key === 'store') && getKeyAliasMap(key))
      || '';

    const cacheKeyTree = ((key === 'counter') && KV_TREE.counter)
      || ((key === 'product') && KV_TREE.product)
      || ((key === 'store') && KV_TREE.store)
      || '';

    kvSourceCache.delete(cacheKey);
    await delCache('kvSource', cacheKey);

    if (cacheKeyMap) {
      kvSourceMapCache.delete(cacheKeyMap);
      await delCache('kvSource', cacheKeyMap);
    }

    if (cacheKeyTree) {
      kvSourceTree.delete(cacheKeyTree);
      await delCache('kvSource', cacheKeyTree);
    }

    await getKvSourceList([], [key]);
  };

  /** 获取当前用户的当前业务序时簿的列定义
   *
   * @param bizident 业务标识
   * @param userId 用户ID
   * @returns
   */
  const getCustomerCol = async (bizident: string, userId: number): Promise<string> => {
    const cache = await getCache('customerCol', `${bizident}_${userId}`);
    return cache || '';
  };

  /** 设置当前用户的当前业务序时簿的列定义
   *
   * @param bizident 业务标识
   * @param userId 用户ID
   * @returns
   */
  const setCustomerCol = async (bizident: string, userId: number, val: string[] | string): Promise<void> => {
    const str = Array.isArray(val) ? val.join(',') : val as string;
    await setCache('customerCol', `${bizident}_${userId}`, str);
  };

  return {
    refeshSysBizParams,
    getSysBizParams,
    getMenus,
    getKvSourceList,
    getProductBySearchKey,
    getKvSourceMap,
    getKvTreeCounter,
    getKvTreeProduct,
    getKvTreeStore,
    refrashCache,
    getCustomerCol,
    setCustomerCol
  };
};
