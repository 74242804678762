<template>
  <div ref="workZone" class="workSpaceWrapper overHidden">
    <slot></slot>
  </div>
</template>

<script setup lang="ts">
import { ref, nextTick } from 'vue';

const workZone = ref<HTMLElement | null>(null);
const setSize = ref<boolean>(true);

nextTick(() => {
  if (setSize.value && workZone.value) {
    sessionStorage.setItem('workZoneWidth', `${workZone.value.clientWidth}`);
    setSize.value = false;
  }
});
</script>

<style lang="scss" scoped>
.workSpaceWrapper {
  display: grid;
  width: 100%;
  grid-template-rows: 1fr;
  background-color: transparent;
}
</style>
