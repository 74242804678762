<template>
  <div class="flexVXY p2">
    <div class="fullW textCenter fontMd fontW7 pb2 mb2 bottomLine">登录密码修改</div>
    <a-form :model="objPwd" size="small">
      <a-form-item field="old" label="旧密码" required>
        <a-input-password v-model="objPwd.old" placeholder="请输入原始旧密码..." allow-clear />
      </a-form-item>
      <a-form-item field="new1" label="新密码" required>
        <a-input-password v-model="objPwd.new1" placeholder="请输入新密码..." allow-clear />
      </a-form-item>
      <a-form-item field="new2" label="新密码" required>
        <a-input-password v-model="objPwd.new2" placeholder="再次输入新密码..." allow-clear />
      </a-form-item>
    </a-form>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { Toaster } from '@/util/uiKit/toaster';
import { apiChangPwd } from '@/util/useApi';

const emptyPwd = () => ({
  old: '',
  new1: '',
  new2: ''
});

const objPwd = ref(emptyPwd());

const emit = defineEmits(['onCancelRePwd']);

/** 执行密码重置 */
const resetPwd = async (): Promise<boolean | undefined> => {
  const pwdOld = objPwd.value.old;
  const pwdNew1 = objPwd.value.new1;
  const pwdNew2 = objPwd.value.new2;
  if (!pwdOld) {
    Toaster.warning('原始密码不能为空');
  } else if (!pwdNew1) {
    Toaster.warning('新密码不能为空');
  } else if (pwdNew1 !== pwdNew2) {
    Toaster.warning('两次新密码不一致');
  } else {
    try {
      const { changeOk } = await apiChangPwd(pwdOld, pwdNew1);
      Toaster.success('密码修改成功');
      objPwd.value = emptyPwd();
      return !!(changeOk);
    } catch (err) {
      Toaster.error('密码修改失败');
    }
  }
};

/** 取消密码重置 */
const cancelRePwd = () => {
  objPwd.value = emptyPwd();
  emit('onCancelRePwd');
};

defineExpose({ resetPwd, cancelRePwd });
</script>
